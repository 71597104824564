.btn{
    color: white;
    font-weight: bold;
    font-size: 1.1em;
    font-weight: bold;
    border: 0;
    padding-left: 20px;
    padding-right: 20px;
    background-color: inherit;
}

.btn:hover{
    background-color: #C8D8E4;
}

.toolbar{
    float: right;
}

.link{
    text-decoration: inherit;
    color: inherit;
}

.toolbar-subsection{
    font-size: 0.9em;
    font-style: italic;
    text-align: right;
}