.tip-container{
    background-color: white;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 20px;
}

.tip-container .text-container{
    padding-top: 15px;
}