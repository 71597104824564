.list-glossary{
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 30px;
}

.list-glossary li{
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: justify;
    font-size: 1.2em;
}

.list-glossary li::marker{
    color: #7F1F24;
}

.term{
    color: #7F1F24;
    font-weight: bold;
}