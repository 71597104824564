.ref-wrapper{
    list-style-type: none;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 0.7em;
}

.ref-wrapper li{
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: justify;
}