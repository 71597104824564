.image-container{
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 10%;
    padding-right: 10%;
    text-align: center;
    max-width: 100%;
}

.image-container img{
    width: 100%;
    object-fit: contain;
}