.po-button-container {
    margin-top: 5vh;
    text-align: center;
    margin-bottom: 30px;
}

.po-button {
    background-color: #7F1F24 !important;
    color: white;
    width: 450px;
    height: 100px;
    font-size: 1.3em !important;
}

.po-button:hover{
    background-color: #61070c !important;
}

.po-blue-section{
    background-color: #C8D8E4;
}