.contact-title-section{
    padding-left: 10%;
}

.contact-title-section .subtitle-container{
    display: inline-block;
    vertical-align: middle;
    padding: 0;
    margin-left: 30px;
}

.contact-title-section img{
    width: 40px;
    display: inline-block;
    vertical-align: middle;
}

b .text-container{
    padding-top: 0;
}

.support-section{
    background-color: #C8D8E4;
    padding-top: 30px;
    padding-bottom: 30px;
}

.partner-labs-section{
    padding-top: 30px;
}