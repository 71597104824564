.lab-setup{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-left: 10%;
    margin-right: 10%;
}

.list-body-precipitation{
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 30px;
}

.list-body-precipitation li{
    text-align: justify;
    font-size: 1.2em;
    margin-top: 5px;
    margin-bottom: 5px;
}

.list-body-precipitation li ul li{
    font-size: 1em;
}

.protocol-table{
    background-color: white;
    border-radius: 20px;
    padding: 0;
    margin: 20px;
}

.protocol-table .image-container{
    padding: 10px;
}