.pd-tip-container{
    background-color: #C8D8E4;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 20px;
    padding-top: 15px;
    padding-bottom: 25px;
}

.pd-tip-container .text-container{
    padding-top: 5px;
    padding-bottom: 5px;
}

.pd-list-body{
    padding-left: 10%;
    padding-right: 10%;
    margin-top: -25px;
    padding-bottom: 30px;
}

.pd-list-body li{
    text-align: justify;
    font-size: 1.2em;
    margin-top: 5px;
    margin-bottom: 5px;
}

.pd-list-body li ul li{
    font-size: 1em;
}

.pd-overview-section-blue{
    background-color: #C8D8E4;
    margin-bottom: -20px;
}

.plasmid-button-container {
    margin-top: 1vh;
    text-align: center;
    margin-bottom: 3vh;
}

.plasmid-button {
    background-color: #7F1F24 !important;
    color: white;
    width: 350px;
    height: 50px;
    font-size: 1.3em !important;
}

.plasmid-button:hover{
    background-color: #61070c !important;
}