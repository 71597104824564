.flags{
    display: flex;
    justify-content: center;
}

.button-flags{
    width: 55px;
    height: 30px;
    margin: 5px;
    padding: 0 !important;
}

.image-flag{
    width: 33px;
    height: 19px;
}