.header{
    width:  100%;
    height: 10%;
    background-color: #5E7F8C;
}

.group-logo{
    max-height: 75px;
    margin: 10px;
}

.toolbar{
    margin-top: auto;
    margin-bottom: auto;
    position: relative;
}

.language-switch{
    margin-top: auto;
    margin-bottom: auto;
    position: relative;
    margin-right: 30px;
}