.contact-wrapper{
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 30px;
    font-size: 1.2em;
}

.contact-name{
    color: #7F1F24;
}

.contact-details{
    list-style-type: none;
    padding: 0;
}