.intro-homepage-wrapper{
    position: relative;
    width: 100%;
    margin-top: -30px;
}

.intro-homepage-wrapper img{
    width: 100%;
    object-fit: cover;
}

.text-intro-homepage-wrapper{
    position: absolute;
    top: 30%;
    width: 100%;
}

.slogan-homepage{
    background-color: #7F1F24;
    margin-top: -30px;
    margin-left: 10%;
    margin-right: 10%;
    vertical-align: middle;
    border-radius: 20px;
}

.slogan-homepage .title-container{
    color: white;
}

.toolkit-button-container {
    margin-top: 100px;
    text-align: center;
    margin-bottom: 30px;
}

.toolkit-button {
    background-color: #7F1F24 !important;
    color: white;
    width: 200px;
    height: 50px;
    font-size: 1.3em !important;
}

.toolkit-button:hover{
    background-color: #61070c !important;
}

.link {
    text-decoration: inherit !important;
    color: inherit !important; 
}

.description-homepage{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.description-homepage .text-container{
    padding-right: 5%;
}

.description-homepage img{
    width: 40%;
}