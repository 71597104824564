.block-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
}

.text-wrapper{
    width: 70%;
    margin-left: 3%;
    margin-right: 3%;
}

.text-wrapper .text-container, .text-wrapper .subtitle-container{
    padding-left: 0;
    padding-right: 0;
}

.image-wrapper{
    margin-left: 3%;
    margin-right: 3%;
}

.image-wrapper .image-container{
    padding-left: 0;
    padding-right: 0;
}

.image-wrapper .image-container img{
    object-fit: contain;
    max-width: 600px;
}