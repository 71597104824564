.biosensor-button-container {
    margin-top: 1vh;
    text-align: center;
    margin-bottom: 3vh;
}

.biosensor-button {
    background-color: #7F1F24 !important;
    color: white;
    width: 350px;
    height: 75px;
    font-size: 1.3em !important;
}

.biosensor-button:hover{
    background-color: #61070c !important;
}

.bs-image{
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.bs-large-image{
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.bs-table{
    background-color: white;
    border-radius: 20px;
    padding: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
}

.bs-table .image-container{
    padding: 10px;
}