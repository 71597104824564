.footer {
    padding-top: 30px;
    padding-left: 100px;
    padding-right: 100px;
    text-align: center;
    color: white;
    background-color: #5E7F8C;
}

.links-section {
    padding-bottom: 30px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
}

.section-title {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 15px;
}

.pages-footer{
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.pages-footer li{
    margin-top: 5px;
    margin-bottom: 5px;
}

.protocols-wrapper{
    display: flex;
    flex-direction: row;
    padding: 0;
}

.protocols-wrapper ul{
    margin-left: 10px;
    margin-right: 10px;
}

.belowline-footer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-top: solid #C8D8E4 1px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.uni-logo{
    height: 60px;
    margin-left: 10px;
    margin-right: 50px;
}

.group-logo-footer {
    width: 120px;
    margin-right: 10px;
}