.rectangle-wrapper{
    position: relative;
    height: 350px;
    width: 550px;
}

.image-about{
    width: 550px;
    height: 350px;
    object-fit: cover;
    text-align: center;
    position: relative;
}

.big-subtitle-wrapper{
    width: 550px;
    padding-top: 5px;
    position: absolute;
    top: 0;
    left: 0;
}

.big-subtitle-wrapper .subtitle-container{
    color: white;
    font-weight: bold;
    text-align: center;
}

.text-wrapper-rectangle{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    border: 2px #7F1F24 solid;
    border-radius: 10px;
}

.subtitle-rectangle .subtitle-container{
    text-align: center;
    font-weight: bold;
}

.text-rectangle{
    padding-top: 15px;
    padding-bottom: 15px;
}

.text-rectangle .text-container{
    padding-top: 10px;
    font-size: 0.9em;
    padding-top: 0;
    padding-bottom: 0;
}

.rectangle-wrapper:hover .text-wrapper-rectangle{
    visibility: visible;
    opacity: 1;
    transition: 0.5s;
}

.rectangle-wrapper:hover .big-subtitle-wrapper{
    opacity: 0;
    transition: 0.5s;
}

.rectangle-wrapper:hover .image-about{
    opacity: 0.15;
    transition: 0.5s;
}