.list-equipment{
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 30px;
    padding-bottom: 30px;
}

.list-equipment li{
    text-align: justify;
    font-size: 1.2em;
}