.overview-title{
    background-color: #5E7F8C;
    margin-top: -30px;
}

.overview-title .title-container{
    color: white;
}

.layered-wave {
    width: 100%;
    height: auto;
}

.layered-wave img{
    width: 100%;
    height: 200px;
    object-fit: fill;
}

.overview-section-blue{
    background-color: #C8D8E4;
}