.img-wrapper{
    position: relative;
    height: 300px;
    width: 300px;
    margin-left: 10px;
    margin-right: 10px;
}

.image-partner{
    width: 300px;
    height: 300px;
    object-fit: contain;
    text-align: center;
}

.text-wrapper-partner{
    position: absolute;
    top: 10px;
    left: 2px;
    width: 100%;
    visibility: hidden;
    opacity: 0;
}

.subtitle-partner{
    padding-left: 5%;
    padding-right: 5%;
    text-align: center;
    font-weight: bold;
    font-size: 1.5em;
    color: #7F1F24;
}

.text-partner{
    margin-top: 10px;
    padding-left: 1%;
    padding-right: 1%;
    font-size: 0.8em;
    font-weight: bold;
    text-align: justify;
    line-height: 1.3;
}

.img-wrapper:hover .text-wrapper-partner{
    visibility: visible;
    opacity: 1;
}

.img-wrapper:hover .image-partner{
    opacity: 0.15;
    transition: 0.5s;
}