.protocol-title{
    background-color: #5E7F8C;
    margin-top: -30px;
}

.protocol-title .title-container{
    color: white;
}

.layered-wave {
    width: 100%;
    height: auto;
}

.layered-wave img{
    width: 100%;
    height: 200px;
    object-fit: fill;
}

.protocol-blue-section{
    background-color: #C8D8E4;
    margin-bottom: -20px;
}

.protocol-button-container {
    margin-top: 5vh;
    text-align: center;
    margin-bottom: 30px;
}

.protocol-button {
    background-color: #7F1F24 !important;
    color: white;
    width: 350px;
    height: 50px;
    font-size: 1.3em !important;
}

.protocol-button:hover{
    background-color: #61070c !important;
}