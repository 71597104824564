.rectangle-container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 60px;
    margin-top: 30px;
    margin-right: 3%;
    margin-left: 3%;
}

.mission{
    background-color: #5E7F8C;
    color: #FFF;
    padding-bottom: 30px;
}

.mission .title-container{
    color: white;
}

.mission .text-container {
    text-align: center;
}

.partners-container{
    display: block;
}